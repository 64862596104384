@if (isSaving) {
  <div class="flex justify-end mt-4 py-4">
    <span class="mr-8 text-lg"> {{ loadingText || "Saving..." }} </span>
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
} @else {
  <div class="flex justify-end mt-4 text-blue-700 text-lg">
    <button (click)="cancel()" type="button" class="focus:outline-none uppercase px-4 py-2">
      {{ cancelActionName || "Cancel" }}
    </button>
  
    @if (submitActionName) {
    <button
      type="submit"
      class="focus:outline-none pl-4 py-2 ml-2 uppercase"
      (click)="submit()"
      [ngClass]="{
          'text-gray-400':
        !valid
      }"
      [disabled]="!valid">
      {{ submitActionName }}
    </button>
    }
  </div>
}