import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "app-dialog-footer",
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: "./dialog-footer.component.html"
})
export class DialogFooterComponent {
  @Input() isSaving: boolean;
  @Input() valid: boolean;
  @Input() submitActionName: string;
  @Input() cancelActionName: string;
  @Input() loadingText: string;
  @Output() cancelAction = new EventEmitter();
  @Output() submitAction = new EventEmitter();

  cancel(): void {
    this.cancelAction.emit();
  }

  submit(): void {
    this.submitAction.emit();
  }
}
